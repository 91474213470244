export const environment = {
	production: false,
	env: 'development',
	channelsAPI: {
		baseUrl: 'https://channelsapi-dev.tagme.com.br',
	},
	liveMenu: {
		url: 'https://live-menu-staging.tagme.com.br/menu',
	},
	partnerId: '643994cb6e7a2ca377e2400c', // Latam
	latamPassUrl: 'https://latampass.uat-mktplace-gopoints.com.br',
	latamUrl: 'https://latam-dev.tagme.com.br',
	googleMapsAPIToken: 'AIzaSyA1p9RdPX2xq2MKmgI2_jCxHqbDs6hGs0E',
}
